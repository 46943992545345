import React, { useState, createContext, useContext } from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "@pai-ui/core/theme/theme-provider";
import axios from "axios";
import { ConfigProvider as PaiUiConfigProvider } from "@pai-ui/core/config/config-provider";
import { WindowConfigProvider } from "./context/WindowContext";
import { MsalConfigProvider } from "./context/useMsal";
// import { ThemeType } from "@pai-ui/core";
import { ThemeType } from "@pai-ui/core/theme/types";

const App = React.lazy(() => import("./App.js"));

export const AppProvider = () => {
  const [login, setLogin] = React.useState(false);
  const [criticalFontsLoaded, setCriticalFontsLoaded] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    const fonts = {
      critical: [
        {
          family: "SF Display",
          weight: 200,
          src: "SF-UI-Display-Light.otf",
        },
        {
          family: "SF Display",
          weight: 400,
          src: "SF-UI-Display-Regular.otf",
        },
        {
          family: "SF Text",
          weight: 400,
          src: "SF-UI-Text-Regular.otf",
        },
        {
          family: "SF Text",
          weight: 200,
          src: "SF-UI-Text-Light.otf",
        },
      ],
      notCritical: [
        {
          family: "SF Display",
          weight: 700,
          src: "SF-UI-Display-Bold.otf",
        },
        {
          family: "SF Display",
          weight: 600,
          src: "SF-UI-Display-Semibold.otf",
        },
        {
          family: "SF Text",
          weight: 700,
          src: "SF-UI-Text-Bold.otf",
        },
        {
          family: "SF Text",
          weight: 700,
          style: "italic",
          src: "SF-UI-Text-BoldItalic.otf",
        },
        {
          family: "SF Text",
          weight: 200,
          style: "italic",
          src: "SF-UI-Text-LightItalic.otf",
        },
        {
          family: "SF Text",
          weight: 600,
          src: "SF-UI-Text-Semibold.otf",
        },
        {
          family: "SF Text",
          weight: 600,
          style: "italic",
          src: "SF-UI-Text-SemiboldItalic.otf",
        },
      ],
    };

    const loadAndAddFont = async (fontConfig) => {
      try {
        const font = new FontFace(
          fontConfig.family,
          `url(${process.env.REACT_APP_CLOUDFRONT_CDN}/fonts/${fontConfig.src})`,
          {
            weight: fontConfig.weight,
            style: fontConfig.style,
          }
        );

        await font.load();
        document.fonts.add(font);
      } catch (err) {
        console.error("ERROR_LOADING_FONTS", err, fontConfig);
      }
    };

    Promise.all(fonts.critical.map(loadAndAddFont)).then(() => {
      setCriticalFontsLoaded(true);

      const timeToWaitUntilAllCriticalAssetsAreLoaded = 500;

      setTimeout(() => {
        fonts.notCritical.forEach(loadAndAddFont);
      }, timeToWaitUntilAllCriticalAssetsAreLoaded);
    });
  }, []);

  React.useEffect(() => {
    if (criticalFontsLoaded) {
      setTimeout(() => {
        setLoading(true);
      }, 3000);
    }
  }, [criticalFontsLoaded]);

  return (
    <>
      <PaiUiConfigProvider
        visualization={{
          resizing: {
            eventName: "customResize",
            debounceMs: 500,
          },
        }}
        http={() => ({
          commonRequestConfig: {
            url: "/viz/GetData",
            baseUrl: process.env.REACT_APP_MICROSERVICE_HOST,
            headers: {
              "x-api-key": process.env.REACT_APP_API_KEY,
              Authorization: "Bearer " + authToken,
            },
          },
        })}
        // access={{
        //   token:
        //     "eyJhbGciOiJIUzI1NiJ9.eyJzZWNyZXQiOiJkcGp4UXc3V2RXQXl4RnhQTUlPT0w3Ym9WbHg2dXg1Um0iLCJleHBpcmF0aW9uRGF0ZSI6IjIwOTktMTItMDZUMDU6MDA6MTEuNzE1WiJ9.jM_ah9uM95peux9xHy13ZLWHYPif3yXYDME1VJJVZD8", // Required
        // }}
        /** #TODO : Below prop values are hardcoded for now. change these to env files later */
        access={{
          secret: "6fCB49F95Cbb89CaC5F52D518c88df17",
          requestConfig: {
            payload: {
              client_name: "panera",
              client_env: "prod",
              product_name: "p.ai",
            },
            headers: {
              "x-api-key": "Eyh7cwF8hV2T5Pzq",
              "Content-Type": "application/json",
            },
          },
        }}
      >
        <ThemeProvider
          type={ThemeType.light}
          palette={{
            common: {
              black: "#000",
              white: "#fff",
            },
            primary: "#0076ff",
            secondary: "#32A29B",
            error: "#ea4335",
            warning: {},
            info: {},
            success: {},
            grey: {
              50: "#F6F6F6",
              100: "#EEEEEE",
              200: "#E2E2E2",
              300: "#CBCBCB",
              400: "#AFAFAF",
              500: "#757575",
              600: "#333333",
              700: "#181b20",
            },
            text: {
              primary: "#646464",
              secondary: "#6b7075",
            },
            background: {
              paper: "#fff",
              default: "",
            },
            border: {
              primary: "#d3d3d3",
            },
            icon: {
              primary: "#d9d9d9",
            },
            components: {
              visualization: {
                axis: {
                  background: {
                    primary: "#acacac",
                  },
                  label: {
                    primary: "#646464",
                  },
                },
                dataLabel: {
                  primary: "#646464",
                },
                referenceLine: {
                  background: {
                    primary: "#acacac",
                  },
                  label: {
                    primary: "#646464",
                  },
                },
                legend: {
                  label: {
                    primary: "#646464",
                  },
                  hidden: {
                    opacity: 0.4,
                  },
                },
                tooltip: {
                  background: {
                    primary: "#fff",
                  },
                  label: {
                    primary: "#646464",
                  },
                  boxShadow: "-1px 1px 5px rgba(0,0,0,.2)",
                },
                activeElement: {
                  border: {
                    // TODO
                    primary: "#32A29B",
                  },
                  background: {
                    // TODO
                    primary: "#32A29B",
                  },
                },
                tableCell: {
                  borderColor: {
                    primary: "#f0f0f0",
                  },
                },
                negativeValue: {
                  // TODO
                  primary: "#ea4335",
                },
                components: {
                  table: {
                    fixedRow: {
                      backgroundColor: "#1d1d1d",
                    },
                    hoverRow: {
                      backgroundColor: "#05242b",
                    },
                    searchIcon: {
                      color: "rgba(0,0,0,.45)",
                    },
                  },
                  dendogramChart: {
                    sectionLine: {
                      backgroundColor: "#000",
                    },
                    node: {
                      backgroundColor: {
                        // TODO
                        primary: "#212429",
                      },
                    },
                    connectionLine: {
                      // TODO
                      primary: "#666666",
                    },
                    value: {
                      backgroundColor: {
                        // TODO
                        primary: "#212429",
                      },
                      color: {
                        // TODO
                        primary: "#fff",
                      },
                    },
                  },
                  radialTreeChart: {
                    text: {
                      color: "black",
                    },
                    link: {
                      backgroundColor: "#666666",
                    },
                    node: {
                      backgroundColor: "#666666",
                    },
                  },
                  waterfallChart: {
                    bar: {
                      backgroundColor: "#01872a",
                    },
                  },
                },
                dataColors: [
                  "#0076ff",
                  "#66aaff",
                  "#005bb5",
                  "#33b5e5",
                  "#99dfff",
                  "#47d1c5",
                  "#85e3d2",
                  "#b2f7eb",
                  "#c4e0f9",
                  "#9bbad3"
                ],
              },
              floatButton: {
                menuItem: {
                  boxShadow:
                    "0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05)",
                },
              },
              ruleBuilder: {
                background: {
                  // TODO
                  dark: "#212429",
                },
              },
              treeSelect: {
                searchInput: {
                  background: "#fff",
                },
                optionSelected: {
                  background: "#fff6e6",
                },
                option: {
                  hoverBackground: "#f5f5f5",
                },
              },
              calendarChart: {
                cell: {
                  emptyBackground: "#1d1d1d",
                  monthBorder: "#303030",
                  dayBorder: "#303030",
                },
              },
              inputSearch: {
                submitButton: {
                  disabledBackground: "#f5f5f5",
                },
              },
              editableFormula: {
                root: {
                  backgroundColorSecondary: "#32A29B",
                },
                suggestion: {
                  hoverBackground: "#181b20",
                },
              },
            },
          }}
          componentsStyles={{
            visualization: {
              root: {
                zoom: 1.1111111,
                background: "#fff",
              },
              header: {
                zoom: 0.9,
              },
            },
          }}
        >
          <React.Suspense fallback="Loading">
            <WindowConfigProvider>
              <MsalConfigProvider>
                <App
                  criticalFontsLoaded={criticalFontsLoaded}
                  loading={loading}
                />
              </MsalConfigProvider>
            </WindowConfigProvider>
          </React.Suspense>
        </ThemeProvider>
      </PaiUiConfigProvider>
    </>
  );
};

ReactDOM.render(<AppProvider />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
